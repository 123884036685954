import { EventEmitter as FbEventEmitter } from 'fbemitter'

export const AppEvents = {
  dashboard_page_loaded: 'Dashboard Page loaded',
  user_login_success: 'User login success',
  user_login_failed: 'User login failed',
  user_clicked_logout_btn: 'User clicked logout button',
  login_page_loaded: 'Login Page loaded',
  signup_page_loaded: 'Sign up page loaded',
  signup_button_clicked: 'Sign up button click',
  signup_success: 'Sign up successful',
  signup_failed: 'Sign up failed',
  organization_signup_page_loaded: 'Organization Sign up page loaded',
  organization_signup_button_clicked: 'Organization Sign up button click',
  organization_signup_success: 'Organization Sign up successful',
  organization_signup_failed: 'Organization Sign up failed',
  bulk_upload_file_added: 'File for bulk upload added',
  user_chunk_bulk_email_verify_upload_started: 'User chunk bulk email verify upload started',
  user_chunk_bulk_email_verify_upload_success: 'User chunk bulk email verify upload success',
  user_chunk_bulk_email_verify_upload_failed: 'User chunk bulk email verify upload failed',
  preset_click_on_overview: 'User clicked on preset in overview page',
  user_clicked_on_buy_credits: 'User clicked on buy credits',
  user_credits_buy_failed: 'User buy credits failed',
  user_buy_credits_success: 'User purchased credits successfully',
  user_individual_profile_showed: 'User individual profile showed',
  quick_validation_results_displayed: 'Quick validation results displayed',
  quick_validation_validate_button_clicked: 'Quick validation validate button clicked',
  quick_validation_export_button_clicked: 'Quick validation export button clicked',
  user_individual_profile_showed_error: 'User individual profile showed error',
  public_user_individual_profile_showed: 'Public instant verify result displayed',
  public_user_individual_profile_showed_error: 'Public instant verify result error',
  user_downloaded_input_file: 'User downloaded input file',
  user_downloaded_result_file: 'User downloaded result file',
  user_downloaded_input_file_errored: 'user input file download erred',
  user_downloaded_result_file_errored: 'user result file download erred',
  user_clicked_on_file_verification_progress: 'User  clicked on file verification progress',
  public_instant_verify_button_clicked: 'public instant verify button  clicked.',
  user_clicked_on_public_instant_verify_button: 'User clicked on instant verify button',
  user_download_result_file_with_more_credits_required: 'User clicked on download result file with credits required',
  user_clicked_on_generate_api_token: 'User clicked on generate api token button',
  user_cancel_email_verify_list: 'User cancel email verify list',
  user_email_verify_list_cancelled: 'User email verify list cancelled',
  user_generated_api_token_successfully: 'User generated api token successfully',
  user_generated_api_token_failure: 'Api token generation for user failed',
  buy_credit_modal_open: 'Buy credit pop up opened',
  login_through_okta: 'User Login through Okta Success',

  //finder
  user_cancel_email_finder_list: 'User cancel email finder list',
  user_email_finder_list_cancelled: 'User email finder list cancelled',

  // general
  welcome_popup_modal_open: 'Welcome pop up displayed',
  welcome_popup_modal_open_onclick: 'Welcome pop up opened by clicking on help button',
  welcome_popup_overview_video_link: 'welcome popup overview video link clicked',
  welcome_popup_email_marketing_link: 'welcome popup email marketing link clicked',
  welcome_popup_api_integration_link: 'welcome popup api integration link clicked',
  welcome_popup_email_finder_link: 'welcome popup email finder link clicked',
  welcome_popup_reason_to_clear_ur_email: 'welcome popup reason to clear your email link clicked',
  welcome_popup_request_demo_link_clicked: 'welcome popup request demo link clicked',
  welcome_popup_skipped: 'welcome popup skipped',
  welcome_popup_close_button_clicked: 'welcome popup close button clicked',
  user_clicked_on_edit_name: 'User clicked on edit name',
  username_update_success: 'User name edit successful',
  username_update_error: 'User name edit erred',
  user_clicked_on_edit_email: 'User clicked on edit email',
  user_email_update_success: 'User email edit successful',
  user_email_update_error: 'User name email erred',
  user_clicked_on_edit_timezone: 'User clicked on edit timezone',
  user_timezone_update_success: 'User timezone edit successful',
  user_timezone_update_error: 'User timezone edit erred',
  user_clicked_on_calculate_price: 'User clicked on Calculate price',
  user_clicked_on_calculate_price_error: 'User clicked on calculate price erred',
  user_clicked_on_signout: 'User clicked on sign out',
  user_signout_success: 'User signed out successfully',
  user_clicked_on_change_password: 'User clicked on change password',
  user_password_update_success: 'User password edit successful',
  user_password_update_error: 'User password edit erred',
  user_clicked_on_edit_phone: 'User clicked on edit phone',
  user_phone_update_success: 'User phone edit successful',
  user_phone_update_error: 'User name phone erred',
  verification_mode_updated: 'verification mode updated',
  user_clicked_on_edit_address: 'User clicked on edit address',
  user_address_update_success: 'User address edit successful',
  user_address_update_error: 'User address edit error',
  user_clicked_auto_pilot_toggle: 'User changed auto pilot status',
  auto_pilot_status_update_success: 'auto pilot status updated successfully',
  auto_pilot_status_update_error: 'auto pilot status update failed',
  user_profile_additional_info: 'User profile additional info collected during account activation',

  // Payment Related Events
  invoice_download_clicked: 'Invoice download clicked',
  user_clicked_apply_coupon: 'User clicked on apply coupon',
  apply_coupon_failed: 'Apply coupon failed',
  apply_coupon_success: 'User coupon applied successfully',
  coupon_apply_via_url_query_string: 'Coupon apply from URL query string',
  user_clicked_on_pay_with_credit_card: 'User clicked on pay with credit card',
  user_clicked_on_pay_with_paypal: 'User clicked on pay with paypal',
  user_payment_failed: 'User payment failed',
  user_payment_success: 'User paid successfully',
  receipt_download_clicked: 'Receipt download clicked',

  // Paypal
  user_paypal_payment_success: 'User paid successfully via paypal',
  user_paypal_payment_failed: 'User paypal payment failed',
  user_clicked_paypal_button: 'User clicked on paypal button',
  user_closed_paypal_window: 'User closed paypal window',
  buy_credit_paypal_window_loaded: 'Paypal window loaded in buy credits popup',

  // Stripe
  add_card_clicked: 'Add card clicked',
  change_default_card_success: 'Change default card success',
  change_default_card_failed: 'Change default card failed',
  delete_card_click: 'Delete card button click',
  delete_card_success: 'Delete card success',
  delete_card_failed: 'Delete card failed',
  add_card_success: 'Add card success',
  add_card_failed: 'Add card failed',
  buy_credit_stripe_window_loaded: 'Stripe window loaded in buy credits popup',
  user_clicked_on_pay_with_stripe: 'User clicked on pay with stripe button',
  user_stripe_payment_success: 'User paid successfully via stripe',
  user_stripe_payment_failed: 'User stripe payment failed',


  overview_page_loaded: 'Overview Page loaded',
  quick_validation_page_loaded: 'Quick Validation page loaded',
  list_page_loaded: 'My List page loaded',
  jobs_page_loaded: 'My Jobs page loaded',
  profile_page_loaded: 'Profile page loaded',
  add_list_page_loaded: 'Add List page loaded',

  //leads
  user_clicked_on_leadfinder_menu: 'User clicked on Leads finder menu',
  user_clicked_on_leads_search: 'User clicked on Leads search',
  user_clicked_on_create_leads_list: 'User clicked on create Leads list  button',
  leads_list_created_successfully: 'Leads list created successfully',
  leads_list_creation_failed: 'Leads list creation failed',
  leads_search_successful: 'Leads search successful',
  leads_search_failed: 'Leads search failed',
  no_leads_found: 'No leads found',
  user_clicked_on_quick_verify: 'User clicked on quick verify button',
  leads_page_loaded: 'Leads page loaded',

  //list info
  list_info_page_loaded: 'My List page loaded',
  user_clicked_refresh: 'User clicked on refresh on my list page',
  user_clicked_verify: 'User clicked on verify on my list page',
  user_clicked_cancel: 'User clicked on cancel action on my list page',
  user_clicked_on_download_submit: 'User clicked on Download submit button',
  user_clicked_on_download_without_append: 'User clicked on Download result file without append',
  user_clicked_on_export_submit: 'User clicked on Export submit button',
  export_successful: 'Export successful',
  export_failed: 'Export failed',
  user_verify_failed: 'list verify failed',


  //integrations
  integrations_page_loaded: 'Integrations page',
  view_connected_accounts_page_loaded: 'View Connected accounts page loaded',
  user_clicked_on_view_accounts: 'user clicked on view mailchimp accounts button',
  user_clicked_on_add_more_accounts: 'user clicked on add more mailchimp accounts button',
  user_clicked_on_sync_accounts: 'USer clicked on Sync accounts',
  mailchimp_account_sync_successful: 'Mailchimp accounts sync successful',
  mailchimp_account_sync_failed: 'Mailchimp accounts sync failed',
  user_clicked_on_mailchimp_signup: 'User clicked on mailchimp signup button',

  user_clicked_close_button_on_unauthorized_modal: 'User clicked close button on unauthorized(401) modal window',
  user_clicked_on_redeem_button: 'User clicked on redeem button',
  user_redeemed_credits_successfully: 'User redeemed Credits successfully',
  user_redeem_credits_failed: 'User redeem Credits failed',
  user_clicked_on_integrations: 'User clicked on integrations coming soon button',

  user_selected_on_email_verify_notification: 'User enabled email notification for verify completion',
  user_deselected_email_verify_notification: 'User disabled email notification for verify completion',
  user_clicked_on_email_export_notification: 'User enabled email notification for export completion',
  user_deselected_on_email_export_notification: 'User disabled email notification for export completion',
  user_selected_list_result_expiry: 'User enabled email notification for list results about to expire',
  user_deselected_list_result_expiry: 'User disabled email notification for list results about to expire',

  user_clicked_on_remove_file: 'User clicked on delete input file button',
  user_input_file_deleted_successfully: 'User deleted input file successfully',
  user_input_file_deletion_erred: 'User input file deletion erreed',
  user_clicked_on_disconnect_account: 'User clicked on disconnect account',
  user_clicked_on_verify_list_from_view_accounts_page: 'User clicked on verify list from View accounts page',
  user_added_list_to_email_verifier_from_view_connected_accounts_page: 'User added list to email verifier from view connected accounts page',

  //active campaign
  user_clicked_on_add_active_campaign_account: 'User clicked on add ActiveCampaign account button',
  active_campaign_account_added_successfully: 'ActiveCampaign account added successfully',
  adding_active_campaign_account_failed: 'Adding ActiveCampaign account failed',
  user_clicked_on_view_active_campaign_accounts: 'user clicked on view ActiveCampaign accounts button',

  //Apollo
  user_clicked_on_add_apollo_account: 'User clicked on add apollo account button',
  apollo_account_added_successfully: 'Apollo account added successfully',
  adding_apollo_account_failed: 'Adding Apollo account failed',
  user_clicked_on_view_apollo_accounts: 'user clicked on view Apollo accounts button',

  //Lemlist
  user_clicked_on_add_lemlist_account: 'User clicked on add lemlist account button',
  lemlist_account_added_successfully: 'Lemlist account added successfully',
  adding_lemlist_account_failed: 'Adding Lemlist account failed',
  user_clicked_on_view_lemlist_accounts: 'user clicked on view Lemlist accounts button',

  //clevertap
  user_clicked_on_add_clevertap_account: 'User clicked on add CleverTap account button',
  clevertap_account_added_successfully: 'CleverTap account added successfully',
  adding_clevertap_account_failed: 'Adding CleverTap account failed',
  user_clicked_on_view_clevertap_accounts: 'user clicked on view CleverTap accounts button',

  //moosend
  user_clicked_on_add_moosend_account: 'User clicked on add moosend account button',
  moosend_account_added_successfully: 'Moosend account added successfully',
  adding_moosend_account_failed: 'Adding Moosend account failed',
  user_clicked_on_view_moosend_accounts: 'user clicked on view Moosend accounts button',


  //mailerlite
  user_clicked_on_add_mailerlite_account: 'User clicked on add mailerlite account button',
  mailerlite_account_added_successfully: 'mailerlite account added successfully',
  adding_mailerlite_account_failed: 'Adding mailerlite account failed',
  user_clicked_on_view_mailerlite_accounts: 'user clicked on view mailerlite accounts button',

  //sengrid
  user_clicked_on_add_sengrid_account: 'User clicked on add sengrid account button',
  sengrid_account_added_successfully: 'sengrid account added successfully',
  adding_sengrid_account_failed: 'Adding sengrid account failed',
  user_clicked_on_view_sengrid_accounts: 'user clicked on view sengrid accounts button',


  //hubspot
  user_clicked_on_add_hubspot_account: 'User clicked on add Hubspot account button',
  hubspot_account_added_successfully: 'Hubspot account added successfully',
  adding_hubspot_account_failed: 'Adding Hubspot account failed',
  user_clicked_on_view_hubspot_accounts: 'user clicked on view Hubspot accounts button',

  //gohighlevel
  user_clicked_on_add_go_high_level_account: 'User clicked on add GoHighLevel account button',
  go_high_level_account_added_successfully: 'GoHighLevel account added successfully',
  adding_go_high_level_account_failed: 'Adding GoHighLevel account failed',
  user_clicked_on_view_go_high_level_accounts: 'user clicked on view GoHighLevel accounts button',
  //admin
  user_changed_dashboard_stats_for_individual: 'User changed dashboard stats for individual',
  user_changed_dashboard_stats_for_organization: 'User changed dashboard stats for organization',
  user_clicked_on_admin_tab: 'User clicked on admin tab',
  user_clicked_on_add_member: 'User clicked on add member',
  user_clicked_on_edit_member: 'User clicked on edit member',
  user_clicked_on_member_list: 'User clicked on member list',
  user_clicked_on_show_member_stats: 'User clicked on show member stats',
  user_clicked_on_show_member_teams: 'User clicked on show member teams',
  user_searched_on_member_list: 'User searched on member list',
  user_changed_role_filter: 'User changed role filter',
  user_clicked_on_add_team: 'User clicked on add team',
  user_clicked_on_create_team: 'User clicked on create team',
  user_clicked_on_edit_team: 'User clicked on edit team',
  user_clicked_on_show_all_member_list: 'User clicked on show all member list',
  user_clicked_on_show_team_members: 'User clicked on show team members',
  user_clicked_on_show_team_member_list: 'User clicked on show team member list',
  user_clicked_on_show_team_stats: 'User clicked on show team stats',
  user_clicked_on_add_member_to_team: 'User clicked on add member to team',
  user_clicked_on_remove_member_from_team: 'User clicked on remove member from team',
  user_succesfully_updated_member: 'User Successfully updated member details',
  user_update_member_failed: 'User member update failed',
  user_succesfully_created_member: 'User Successfully created member',
  user_create_member_failed: 'User member create failed',
  user_create_team_failed: 'User team create failed',
  user_update_team_failed: 'User team update failed',
  user_successfully_created_the_team: 'User Successfully created team',
  user_successfully_updated_the_team: 'User Successfully updated team',

  //subscription events
  subscription_tab_loaded: 'Pricing subscription tab loaded',
  user_selected_subscription_plan: 'Subs plan clicked',
  default_selected_plan: 'Default selected plan',
  user_clicked_subscribe_btn_modal: 'User clicked on subscribe button to open modal',
  user_clicked_paypal_subscription: 'User clicked on paypal subscribe button',
  user_paypal_subscribe_create_failed: 'paypal create subscription api failed',
  user_paypal_subscribe_approve_failed: 'paypal approve subscription api failed',
  user_closed_paypal_subscribe_window: 'User closed paypal payment window',
  user_cancelled_current_plan: 'User cancelled current plan',
  user_cancelled_current_plan_failed: 'User cancel plan api failed',
  user_clicked_cancel_on_profile: 'User clicked cancel subscription on profile',
  user_clicked_on_toggle_btn: 'User clicked on toggle button',
  pricing_page_loaded: 'Pricing page loaded',
  plans_page_loaded: 'Plans page loaded',
  user_clicked_on_cancel_subscription_btn: 'User clicked on cancel subscription button',
  user_clicked_on_upgrade_from_plans_page: 'User clicked on upgrade from plans page',
  annual_plans_page_loaded: 'Annual plans page loaded',
  monthly_plans_page_loaded: 'Monthly plans page loaded',
  one_time_plans_page_loaded: 'One time plans page loaded',

  user_clicked_on_subscribe_button: 'User clicked on subscribe button',
  user_clicked_on_buy_button: 'User clicked on buy button',
  cancel_modal_opened: 'Cancel subscription modal opened',
  user_clicked_on_show_current_plan: 'User clicked on show current plan',
  user_clicked_on_compare_plans: 'User clicked on compare plans',
  user_tried_to_buy_one_time_without_active_subscription: 'User tried to buy one time without active subscription',
  user_tried_to_addon_time_without_active_subscription: 'User tried to buy addon without active subscription',
  delete_card_modal_opened: 'Delete card modal opened',

  user_clicked_on_switch_to_subscription_in_payg_alert: 'User clicked on switch to subscription in payg alert',
  user_closed_payg_alert_msg: 'User closed payg alert message',
  user_clicked_on_continue_with_one_time_purchase: 'User clicked on continue with one time purchase',
  payg_alert_modal_opened: 'Payg alert modal opened',

  //Billing page
  billing_page_loaded: 'Billing page loaded',
  user_clicked_on_edit_billing_address: 'User clicked on edit billing address',
  billing_address_popup_opened: 'Billing address popup opened',
  user_clicked_on_add_billing_address_btn: 'User clicked on add billing address button',
  user_clicked_on_edit_billing_address_btn: 'User clicked on edit billing address button',


  add_card_modal_opened: 'Add card modal opened',
  card_added_successfully: 'Card added successfully',
  card_addition_failed: 'Card addition failed',
  user_clicked_on_delete_card: 'User clicked on delete card',
  card_deleted_successfully: 'Card deleted successfully',
  card_deletion_failed: 'Card deletion failed',
  user_clicked_on_change_default_card: 'User clicked on change default card',
  default_card_changed_successfully: 'Default card changed successfully',

  //user activities
  activities_page_loaded: 'Activities Page loaded',
  preset_click_on_activities: 'Clicked on preset on user activity',
  clicked_refresh_activity: 'Clicked on user activity refresh',
  selected_bulk_filter: 'selected filter on user activity',
  clicked_download_activity: 'Clicked on user activity download',
  instant_download_activity_success: 'Instant user activity download success',
  confirmed_download_activity_via_job: 'User confirmed activity download via job',
  declined_download_activity_via_job: 'User cancelled activity download via job',

  //EV usage reports
  email_verifier_usage_reports_page_loaded: 'UsageReports Page loaded',
  preset_click_on_email_verifier_usage_reports: 'Clicked on preset on usage reports',
  clicked_refresh_email_verifier_usage_reports: 'Clicked on usage reports refresh',
  selected_members_filter_email_verifier_report: 'selected filter on usage reports',
  clicked_download_email_verifier_usage_reports: 'Clicked on usage reports download',

  //EF usage reports
  email_finder_usage_reports_page_loaded: 'Email Finder UsageReports Page loaded',
  preset_click_on_email_finder_usage_reports: 'Clicked on preset on Email Finder usage reports',
  clicked_refresh_email_finder_usage_reports: 'Clicked on Email Finder usage reports refresh',
  selected_members_filter_email_finder_report: 'selected filter on Email Finder usage reports',
  clicked_download_email_finder_usage_reports: 'Clicked on Email Finder usage reports download',

  //Prospect usage reports
  prospect_usage_reports_page_loaded: 'Prospect UsageReports Page loaded',
  preset_click_on_prospect_usage_reports: 'Clicked on preset on Prospect usage reports',
  clicked_refresh_prospect_usage_reports: 'Clicked on Prospect usage reports refresh',
  selected_prospect_report_members_filter: 'selected filter on Prospect usage reports',
  clicked_download_prospect_usage_reports: 'Clicked on Prospect usage reports download',

  //overview usage reports
  overview_usage_reports_page_loaded: 'Overview UsageReports Page loaded',
  preset_click_on_overview_usage_reports: 'Clicked on preset on Overview usage reports',
  clicked_refresh_overview_usage_reports: 'Clicked on Overview usage reports refresh',
  selected_members_filter_overview_report: 'selected filter on Overview usage reports',
  clicked_download_overview_usage_reports: 'Clicked on Overview usage reports download',

  //team usage reports
  team_usage_reports_page_loaded: 'UsageReports Page loaded',
  preset_click_on_team_usage_reports: 'Clicked on preset on usage reports',
  clicked_refresh_team_usage_reports: 'Clicked on usage reports refresh',
  selected_team_members_filter: 'selected filter on usage reports',
  clicked_download_team_usage_reports: 'Clicked on usage reports download',

  //verify modes
  user_clicked_verify_mode_option: 'User Clicked verify mode option',

  //white black list settings
  clicked_add_list_modal: 'Clicked on Add WBList Modal',
  clicked_remove_list_modal: 'Clicked on Remove WBList Modal',
  clicked_refresh_wb_list: 'Clicked on WB List refresh',
  selected_wb_list_filter: 'Selected Filter on Wb List',
  clicked_add_list_button: 'Clicked on Add WBList Button',
  clicked_remove_list_button: 'Clicked on Remove WBList Button',

  //email finder settings
  include_role_format_update_success: 'include role format updated successfully',
  strict_domain_check_update_success: 'strict domain check updated successfully',
  include_role_format_update_error: 'include role format update failed',
  strict_domain_check_update_error: 'strict domain check update failed',

  //gibberish threshold settings
  gibberish_thershold_update_success: 'gibberish threshold updated successfully',
  gibberish_thershold_update_error: 'gibberish threshold update failed',
  user_clicked_gibberish_threshold_slider: 'user clicked on gibberish threshold slider',

  //bulk  upload
  user_chunk_bulk_upload_file_duplicate: 'User chunk bulk upload file duplicate',
  user_chunk_bulk_upload_file_duplicate_accept: 'User chunk bulk upload file duplicate accepted',
  user_chunk_bulk_upload_file_duplicate_cancel: 'User chunk bulk upload file duplicate cancelled',

  // sample files
  email_verify_csv_sample_filed_clicked: 'User clicked on email verifier csv sample file',
  email_verify_xlsx_sample_filed_clicked: 'User clicked on email verifier xlsx sample file',
  email_finder_csv_sample_filed_clicked: 'User clicked on email finder csv sample file',
  email_finder_xlsx_sample_filed_clicked: 'User clicked on email finder xlsx sample file',

  // prospect
  prospect_home_page_loaded: 'Prospect Home page loaded',
  prospect_search_people_page_loaded: 'Prospect Search People page loaded',
  prospect_search_company_page_loaded: 'Prospect Search Company page loaded',
  prospect_list_page_loaded: 'Prospect List page loaded',
  prospect_people_profile_view_page_loaded: 'Prospect People profile view page loaded',
  prospect_company_profile_view_page_loaded: 'Prospect Company profile view page loaded',
  prospect_save_search_page_loaded: 'Prospect save search page loaded',
  user_clicked_list_enrich: 'User clicked list enrich',
  user_list_enrich_failed: 'User list enrich failed',
  user_clicked_list_export: 'User clicked list export',
  user_list_export_failed: 'User list export failed',
  user_clicked_list_remove: 'User clicked list remove',
  user_list_remove_success: 'User list remove success',
  user_list_remove_failed: 'User list remove failed',
  user_list_remove_modal: 'User list remove modal opened',
  user_google_sign_in: 'User Google sign in popup',
  user_clicked_reveal_prospect: 'User clicked reveal prospect',
  user_reveal_prospect_failed: 'User reveal prospect failed',
  user_prospect_add_modal: 'User prospect add modal opened',
  user_clicked_prospect_add: 'User clicked prospect add',
  user_prospect_add_failed: 'User prospect add failed',
  user_prospect_removal_modal: 'User prospect removal modal opened',
  user_clicked_prospect_removal: 'User clicked prospect remove',
  user_prospect_removal_failed: 'User prospect remove failed',
  user_clicked_save_search_filter: 'User clicked save search filter',
  user_save_search_modal: 'User save search modal opened',
  user_unlink_google_account: 'User unlinked google account',
  user_unlink_google_account_fail: 'User unlink google account failed',
  user_clicked_chrome_extension_download: 'User clicked Chrome Extension Download',
  user_clicked_prospect_search_filters: 'User clicked prospect search filters',
  user_used_prospect_search_in_home: 'User used prospect search in home',

  // g2crowd
  user_g2crowd_modal_open: 'User g2crowd modal opened',
  user_g2crowd_modal_close: 'User g2crowd modal closed',

  //extension uninstall
  extension_uninstall_page_loaded: 'extension uninstall page loaded',
  extension_uninstall_form_submitted: 'extension uninstall form submitted',

  //app reverse lookup
  app_reverse_lookup_page_loaded: 'App reverse lookup page loaded',
  app_reverse_lookup_search_clicked: 'App reverse lookup search clicked',

  //public reverse lookup
  public_reverse_lookup_page_loaded: 'Public reverse lookup page loaded',
  public_reverse_lookup_search_clicked: 'Public reverse lookup search clicked',

  upgrade_to_team_modal_opened: 'Team account upgrade modal opened',

  //okta
  user_click_okta_creds_save: 'User clicked Okta settings save button',
  okta_creds_save_success: 'Okta credentials saved successfully',
  okta_creds_save_failure: 'Okta credentials failed to save'

}

class AppEventEmitter extends FbEventEmitter {
  constructor(args) {
    super(args)
  }
  // __emitToSubscription(subscription, eventType) {
  // //   var args = Array.prototype.slice.call(arguments, 2);
  // //   var start = Date.now();
  // //   subscription.listener.apply(subscription.context, args);
  // //   var time = Date.now() - start;
  // //   console.log('callback-time', {eventType, time});
  //   console.log(`event ${eventType} callback called`)
  // }
  // emit(eventType, args) {
  //   super.emit(eventType, args)
  //   console.log(`event ${eventType} emitted`)
  // }
}

export default new AppEventEmitter()
